
import { Component, Vue } from 'vue-property-decorator'
import { FileInfo } from '@/types/common'
import { EventInfo } from '@/types/engineering'
import UploadFile from '@/components/uploadFile/Index.vue'
import { ElForm } from 'element-ui/types/form'

@Component({
  name: 'AddEvent',
  components: { UploadFile }
})
export default class AddEvent extends Vue {
  private formInfo: EventInfo = {
    /** 工程信息id */
    infoId: '',
    /** 事件详情 */
    eventDetail: '',
    /** 发生日期 */
    occurDate: '',
    /** 发生地址 */
    occurAddr: '',
    /** 负责人Id */
    leaderId: '',
    /** 事件图片列表 */
    resourceList: []
  }

  private tableData = []
  private engineeringList = []
  private rulesForm = {
    infoId: [
      { required: true, message: '请选择工程名称', trigger: 'change' }
    ],
    eventDetail: [
      { required: true, message: '请输入事件详情', trigger: 'change' }
    ],
    leaderId: [
      { required: true, message: '请选择负责人', trigger: 'change' }
    ]
  }

  created (): void {
    this.getengineeringList()
    this.personnelGet()
  }

  // 获取工程列表
  getengineeringList () {
    this.$axios.get(this.$apis.engineering.selectEngineeringInfoByList).then(res => {
      this.engineeringList = res.list || []
    })
  }

  // 人员列表数据请求
  personnelGet () {
    this.$axios.get(this.$apis.engineering.selectNoAdminList).then((res) => {
      console.log(res)
      this.tableData = res || []
    })
  }

  // 文件上传成功返回
  onSuccess (file: FileInfo) {
    this.formInfo.resourceList.push(file)
  }

  imgRemove (index: number) {
    this.formInfo.resourceList.splice(index, 1)
  }

  // 保存
  onSubmit () {
    (this.$refs.formInfo as ElForm).validate((valid) => {
      if (valid) {
        this.$axios.post(this.$apis.engineering.insertEngineeringEvent, this.formInfo).then(() => {
          this.$message.success('添加成功')
        }).finally(() => {
          this.$router.back()
        })
      }
    })
  }
}
